import { object } from 'yup'
import { type OptionalObjectSchema } from 'yup/lib/object'
import { type AnyObject } from 'yup/lib/types'

import { useGetProjects } from 'api/hooks'

import { useCreateProjectsContext } from 'context'

import { useFormNavigation } from 'hooks'
import { useFlags } from 'hooks/flags'

import {
  getGenericValuesSchemaMap,
  useSteps,
  warehouseValuesSchema,
  type CreateFormNavigationSteps
} from 'modules/Projects/CreateProject/CreateProjectForm'

import { type ProjectConfigurationType } from 'types'

export const useValidationSchema = (
  projectConfiguration: ProjectConfigurationType
): OptionalObjectSchema<AnyObject> => {
  const { projectType } = useCreateProjectsContext()
  const { data: projects } = useGetProjects()
  const steps = useSteps({ projectConfiguration })
  const { currentStep } = useFormNavigation<CreateFormNavigationSteps>(steps)
  const { enableKeyPairAuthentication } = useFlags()

  const warehouseSchema = warehouseValuesSchema(
    projectType,
    undefined,
    !!enableKeyPairAuthentication
  )

  const valuesSchema = {
    ...getGenericValuesSchemaMap(projects?.map((x) => x.name) ?? []),
    ...warehouseSchema
  }
  return currentStep ? valuesSchema[currentStep] : object({})
}

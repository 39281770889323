import { Fragment, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'

import { useFormikContext } from 'formik'
import { capitalize } from 'lodash'

import { CREATE_PROJECT_STORAGE_ITEM } from 'constants/persistance'
import {
  PROJECTS_ADD,
  PROJECTS_AGENT_DEPLOYMENT,
  PROJECTS_CLOUD_CREDENTIALS,
  PROJECTS_CONFIGURATION,
  PROJECTS_CREATE_AGENT,
  PROJECTS_CREATE_ENVIRONMENT,
  PROJECTS_CREDENTIALS,
  PROJECTS_DEFAULTS,
  PROJECTS_NEW_PROJECT,
  PROVIDER_SELECTION,
  REPOSITORY_SELECTION,
  ROOT
} from 'constants/route'

import * as Sources from 'modules/Common/WarehouseSources'
import AddNewProject from 'modules/FormsContent/AddNewProject'
import AgentDeployment from 'modules/FormsContent/AgentDeployment'
import CreateAgent from 'modules/FormsContent/CreateAgent'
import CreateEnvironment from 'modules/FormsContent/CreateEnvironment'
import GitProviderSelection from 'modules/FormsContent/GitProviderSelection'
import GitRepositorySelection from 'modules/FormsContent/GitRepositorySelection'
import ProjectConfigurationSelection from 'modules/FormsContent/ProjectConfigurationSelection'
import {
  getInitialValues,
  previousStepOrDefault,
  useSteps,
  type CreateFormNavigationSteps,
  type CreateProjectFormikValueTypes
} from 'modules/Projects/CreateProject/CreateProjectForm'

import { ProjectConfigurationType } from 'types'

const CreateProjectRoutes = () => {
  const navigate = useNavigate()
  const { setValues, values, setFieldValue } =
    useFormikContext<CreateProjectFormikValueTypes>()
  const { t } = useTranslation()
  const allSteps = useSteps({
    projectConfiguration: values.projectConfiguration
  })
  const persistedFormValues = window.sessionStorage.getItem(
    CREATE_PROJECT_STORAGE_ITEM
  )
  const projectType = persistedFormValues
    ? JSON.parse(persistedFormValues).type
    : ''

  const warehouseRoutePaths: CreateFormNavigationSteps[] = [
    PROJECTS_CREDENTIALS,
    PROJECTS_CLOUD_CREDENTIALS,
    PROJECTS_DEFAULTS
  ]

  const onFormCancel = () => {
    navigate(ROOT)
  }

  const handlePreviousPage = (route: CreateFormNavigationSteps) => {
    navigate(previousStepOrDefault(route, allSteps), {
      replace: true
    })
  }

  const modalContent = t('createProject.modal.content')

  const warehouseRoutes = Object.entries(Sources).reduce<JSX.Element[]>(
    (result, [label, Component]) => {
      if (label === capitalize(projectType)) {
        result.push(
          <Fragment key={`${label}-route`}>
            {warehouseRoutePaths.map((dwhRoute) => (
              <Route
                key={`${label}-${dwhRoute}`}
                path={`${dwhRoute}/*`}
                element={
                  <Component
                    showCancel
                    onCancel={onFormCancel}
                    onPrevious={() => {
                      handlePreviousPage(dwhRoute)
                    }}
                    showPrevious
                    modalContent={modalContent}
                    keyPairEnabled
                  />
                }
              />
            ))}
          </Fragment>
        )
      }
      return result
    },
    []
  )

  const redirectToStartOfForm = useCallback(() => {
    navigate(PROJECTS_NEW_PROJECT, { replace: true })
  }, [navigate])

  // If session storage gets deleted then redirect to the start of the form
  useEffect(() => {
    if (
      !persistedFormValues &&
      window.location.pathname !== `/${PROJECTS_ADD}/${PROJECTS_NEW_PROJECT}`
    ) {
      redirectToStartOfForm()
      setValues(getInitialValues())
    }
  }, [navigate, persistedFormValues, redirectToStartOfForm, setValues])

  return (
    <Routes>
      <Route
        path="*"
        element={<Navigate replace to={PROJECTS_NEW_PROJECT} />}
      />
      <Route
        path={`${PROJECTS_NEW_PROJECT}/*`}
        element={
          <AddNewProject
            showCancel
            onCancel={() => {
              navigate(ROOT)
            }}
            modalContent={modalContent}
          />
        }
      />
      <Route
        path={`${PROJECTS_CONFIGURATION}/*`}
        element={
          <ProjectConfigurationSelection
            onCancel={onFormCancel}
            onPrevious={() => {
              handlePreviousPage(PROJECTS_CONFIGURATION)
            }}
            modalContent={modalContent}
          />
        }
      />
      {values.projectConfiguration !== ProjectConfigurationType.Matillion && (
        <>
          <Route
            path={`${PROVIDER_SELECTION}/*`}
            element={
              <GitProviderSelection
                onCancel={onFormCancel}
                modalContent={modalContent}
                onPrevious={() => {
                  handlePreviousPage(PROVIDER_SELECTION)
                }}
              />
            }
          />
          <Route
            path={`${REPOSITORY_SELECTION}/*`}
            element={
              <GitRepositorySelection
                onCancel={onFormCancel}
                modalContent={modalContent}
                onPrevious={() => {
                  handlePreviousPage(REPOSITORY_SELECTION)
                }}
              />
            }
          />
        </>
      )}
      <Route
        path={`${PROJECTS_AGENT_DEPLOYMENT}/*`}
        element={
          <AgentDeployment
            showCancel
            onCancel={onFormCancel}
            onPrevious={() => {
              // Special case due to the auto navigate
              // As we don't make any API calls for "matillion" git we redirect automatically upon provider selection
              // This is to avoid the redirect so the user can change providers if needed
              // We also want to skip the repository_list page when provider "matillion" is selected
              if (values.provider === 'matillion') {
                setFieldValue('provider', '')
                handlePreviousPage(REPOSITORY_SELECTION)
              } else {
                handlePreviousPage(PROJECTS_AGENT_DEPLOYMENT)
              }
            }}
            showPrevious
            modalContent={modalContent}
          />
        }
      ></Route>
      <Route
        path={`${PROJECTS_CREATE_AGENT}/*`}
        element={
          <CreateAgent
            showCancel
            onCancel={onFormCancel}
            onRedirectPrevious={() => {
              navigate(previousStepOrDefault(PROJECTS_CREATE_AGENT, allSteps), {
                replace: true
              })
            }}
            onRedirectNext={() => {
              navigate(PROJECTS_CREATE_ENVIRONMENT, { replace: true })
            }}
            modalContent={modalContent}
          />
        }
      />
      <Route
        path={`${PROJECTS_CREATE_ENVIRONMENT}/*`}
        element={
          <CreateEnvironment
            showCancel
            onCancel={onFormCancel}
            onPrevious={() => {
              handlePreviousPage(PROJECTS_CREATE_ENVIRONMENT)
            }}
            showPrevious
            modalContent={modalContent}
          />
        }
      />
      {warehouseRoutes}
    </Routes>
  )
}

export default CreateProjectRoutes

import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Typography } from '@matillion/component-library'

import { useDeleteStreamingPipeline } from 'api/hooks/streaming'
import { type GETStreamingPipelineSummaryResponse } from 'api/types'

import { RedWarning } from 'components/Icon'
import ConfirmCancelModal from 'components/Modal/ProjectExplorerModal/ProjectExplorerModal'

import { StreamingPipelineStatus } from 'types/Streaming'

import classes from './DeletePipelineModal.module.scss'

interface DeletePipelineModalProps {
  onCancel: VoidFunction
  pipeline: GETStreamingPipelineSummaryResponse
}

export const DeletePipelineModal: FunctionComponent<
  DeletePipelineModalProps
> = ({ onCancel, pipeline }) => {
  const { projectId } = useParams()
  const { t } = useTranslation('translation', {
    keyPrefix: 'streamingListing.deleteModal'
  })

  const { mutateAsync: mutateDelete } = useDeleteStreamingPipeline(projectId!)

  const heading =
    pipeline.status === StreamingPipelineStatus.UNKNOWN
      ? t('title.agentDisconnected')
      : t('title.agentConnected')

  const body = (
    <div className={classes.DeletePipelineModal__Body}>
      <Typography>
        {t('supportText.action', { pipelineName: pipeline.name })}
      </Typography>
      {pipeline.status === StreamingPipelineStatus.UNKNOWN && (
        <Typography>{t('supportText.unknownPipeline')}</Typography>
      )}
    </div>
  )

  return (
    <ConfirmCancelModal
      heading={heading}
      additionalContent={body}
      validate={t('submit')}
      onCancel={onCancel}
      onValidate={() => {
        mutateDelete({
          pipelineId: pipeline.id,
          pipelineName: pipeline.name
        })
        onCancel()
      }}
      icon={<RedWarning />}
    />
  )
}

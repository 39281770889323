import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Typography } from '@matillion/component-library'
import { heap, useUser } from '@matillion/hub-client'

import classes from 'modules/Projects/StreamingListing/components/NoEntitlement/NoEntitlement.module.scss'
import { NoEntitlementModal } from 'modules/Projects/StreamingListing/components/NoEntitlementModal/NoEntitlementModal'

export const NoEntitlement = () => {
  const { t } = useTranslation()
  const { organisation } = useUser()

  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    heap.track('learn_more_button_visible_nonent')
  }, [])

  return (
    <div
      className={classes.NoEntitlement}
      data-testid="no-streaming-entitlement-container"
    >
      <Typography format="tm" className={classes.NoEntitlement__Title}>
        {t('streamingListing.common.title')}
      </Typography>
      <Typography format="bcm" className={classes.NoEntitlement__SupportText}>
        {t('streamingListing.common.supportText')}
      </Typography>
      <Typography format="bcm">
        {t('streamingListing.noEntitlement.enterpriseOnly')}
      </Typography>
      <div className={classes.NoEntitlement__ButtonWrapper}>
        <Button
          onClick={() => {
            setShowModal(true)
          }}
          text={t('streamingListing.noEntitlement.learnMore')}
          alt="secondary"
          className={classes.NoEntitlement__Button}
          size="lg"
          data-testid="list-streaming-learn-more-button"
        />
      </div>
      {showModal && (
        <NoEntitlementModal
          onClose={() => {
            setShowModal(false)
          }}
          organisationSubdomain={organisation.subdomain}
        />
      )}
    </div>
  )
}

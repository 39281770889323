/**
 * This enum is used to determine which git provider the user has selected.
 * Notice the values below are lowercased and these are what the GCL is expecting.
 */
export enum GitProvider {
  Matillion = 'matillion',
  Github = 'github',
  AzureDevops = 'azure-devops',
  Gitlab = 'gitlab',
  Bitbucket = 'bitbucket'
}

// TO-DO: Remove the below enum when DPC-1438[https://matillion.atlassian.net/browse/DPC-14830] is completed.
/**
 * This enum is used to determine the git provider we get back
 * from the getRepositories call to the git service.
 * Notice that the values below is how the provider is stored in the GIT DB.
 */
export enum GitProviderRepositoriesResponse {
  Matillion = 'MATILLION',
  Github = 'GITHUB',
  AzureDevops = 'AZURE_DEVOPS',
  Gitlab = 'GITLAB',
  Bitbucket = 'BITBUCKET'
}

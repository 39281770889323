import { useEffect, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@matillion/component-library'
import { heap } from '@matillion/hub-client'

import { Header, HeaderTitle, HeaderWithCTA } from 'components/Header'

import { Requirements } from 'modules/Projects/StreamingListing/components/Requirements/Requirements'

interface StreamingHeaderProps {
  onAddClick?: VoidFunction
}

const StreamingHeader: FunctionComponent<StreamingHeaderProps> = ({
  onAddClick
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    heap.track('add_new_pipeline_button_visible')
  }, [])

  return (
    <Header fullWidth centerAlign={false}>
      <HeaderWithCTA>
        <>
          <HeaderTitle
            data-testid="streaming-title"
            centerAlign={false}
            headerElement="h2"
            format="tm"
            withBottomSpace={false}
          >
            {t('streamingListing.title')}
          </HeaderTitle>
          {onAddClick && (
            <Button
              text={t('streamingListing.addButtonText')}
              onClick={onAddClick}
              data-testid="add-streaming-pipeline-button"
              alt="positive"
            />
          )}
          <Requirements />
        </>
      </HeaderWithCTA>
    </Header>
  )
}

export default StreamingHeader

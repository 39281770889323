import { useCallback, useState } from 'react'
import { useNavigate, type To } from 'react-router-dom'

import { type AxiosError } from 'axios'
import { type FormikHelpers, type FormikProps } from 'formik'
import { t } from 'i18next'
import { v4 as uuidv4 } from 'uuid'

import { Toaster } from '@matillion/component-library'

import { usePostSecretLocation } from 'api/createProjectForm/hooks'
import {
  createGetWarehouseComputeResourceMutationData,
  createPostSecretLocationMutationData
} from 'api/createProjectForm/mutation'
import { WarehouseLookupTypes } from 'api/createProjectForm/types'
import {
  useGetMha,
  useGetProjects,
  useMutateDatabricksComputeResources,
  useMutateWarehouseDefaults,
  usePostMha,
  usePostProject,
  usePostProjectV2,
  usePostSecretValueV2,
  type TError
} from 'api/hooks'
import { usePatchSecretValueV2 } from 'api/hooks/usePatchSecretValueV2'
import {
  createProjectMutationData,
  createSecretValueV2MutationData,
  createSecretValueV2UpsertMutationData,
  createWarehouseDefaultsMutation,
  type CreateProjectFormValuesTypes
} from 'api/mutation'
import type {
  ErrorResponse,
  PATCHSecretValueV2Request,
  POSTProjectResponse,
  POSTSecretValueV2Request
} from 'api/types'

import Form from 'components/Form'
import classes from 'components/Form/Form.module.scss'

import { CREATE_PROJECT_STORAGE_ITEM } from 'constants/persistance'
import {
  AppRoutes,
  PROJECTS_CLOUD_CREDENTIALS,
  PROJECTS_CONFIGURATION,
  PROJECTS_CREATE_AGENT,
  PROJECTS_CREATE_ENVIRONMENT,
  PROJECTS_CREDENTIALS,
  PROVIDER_SELECTION
} from 'constants/route'
import { StatusCodes } from 'constants/statusCodes'

import { useCreateProjectsContext } from 'context'

import { useFormNavigation } from 'hooks'
import { useFlags } from 'hooks/flags'

import {
  getInitialValues,
  useSteps
} from 'modules/Projects/CreateProject/CreateProjectForm/CreateProjectForm.util'
import { type CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm/types'
import CreateProjectRoutes from 'modules/Projects/CreateProject/CreateProjectRoutes'
import { useValidationSchema } from 'modules/Projects/CreateProject/hooks/useValidationSchema'
import { useHandleCHASecretReferences } from 'modules/Projects/EditEnvironment/hooks/useHandleCHASecretReferences'
import { isMHARunning } from 'modules/utils'

import {
  AgentsSecretsHost,
  Warehouse,
  type ProjectConfigurationType
} from 'types'
import { CloudProviders } from 'types/CloudProviders'

const CreateProjectForm = () => {
  const browserSessionData = window.sessionStorage.getItem(
    CREATE_PROJECT_STORAGE_ITEM
  )
  const initialValues = getInitialValues(
    browserSessionData && JSON.parse(browserSessionData)
  )

  const navigate = useNavigate()
  const { refetch } = useGetProjects()
  const { refetch: mhaRefetch } = useGetMha({ options: { enabled: false } })
  const { mutateAsync: mutateAsyncProject } = usePostProject()
  const { mutateAsync: mutateAsyncProjectV2 } = usePostProjectV2()
  const { mutateAsync: mutateAsyncSecretLocation } = usePostSecretLocation()
  const { mutateAsync: mutateAsyncSecretValueV2Patch } = usePatchSecretValueV2()
  const { mutateAsync: mutateAsyncSecretValueV2Post } = usePostSecretValueV2({
    showErrorDetailMessage: false
  })
  const { mutateAsync: mutateAsyncMHA } = usePostMha({})
  const { makeToast, clearToasts } = Toaster.useToaster()
  const { enableKeyPairAuthentication } = useFlags()
  const handleCHASecretReferences = useHandleCHASecretReferences()

  const [projectConfiguration, setProjectConfiguration] =
    useState<ProjectConfigurationType>(initialValues.projectConfiguration)

  const validationSchema = useValidationSchema(projectConfiguration)
  const { isLastStep, nextStep, currentStep, progress, stepIndex } =
    useFormNavigation(useSteps({ projectConfiguration }))
  const {
    awsSecretReferenceId,
    setProjectType,
    setSecretLocationId,
    setSecretReferenceId,
    secretLocationId
  } = useCreateProjectsContext()

  const { mutateAsync: mutateAsyncWarehouseRoleLookup } =
    useMutateWarehouseDefaults(WarehouseLookupTypes.ROLE)

  const { mutateAsync: mutateAsyncWarehouseDatabaseLookup } =
    useMutateWarehouseDefaults(WarehouseLookupTypes.DATABASE)

  const { mutateAsync: mutateAsyncWarehouseLocationLookup } =
    useMutateWarehouseDefaults(WarehouseLookupTypes.LOCATION)

  const { mutateAsync: mutateAsyncWarehouseComputeResourceLookup } =
    useMutateDatabricksComputeResources()

  const handleOnSubmit = (id: string) => {
    refetch()
    navigate(AppRoutes.getProjectDetails(id), {
      replace: true
    })
  }

  const publishProject = async (
    values: CreateProjectFormikValueTypes,
    onPublishStatus: (
      success: boolean,
      projectResponse?: POSTProjectResponse | TError,
      projectName?: string
    ) => void,
    onFinish: () => void
  ) => {
    const useV2Flow =
      enableKeyPairAuthentication && values.type === Warehouse.Snowflake
    const mutateFunction =
      enableKeyPairAuthentication && values.type === Warehouse.Snowflake
        ? mutateAsyncProjectV2
        : mutateAsyncProject
    return mutateFunction({
      values: createProjectMutationData(
        {
          ...(values as CreateProjectFormValuesTypes),
          secretLocationId
        },
        undefined,
        useV2Flow
      )
    })
      .then((response) => {
        onPublishStatus(
          true,
          {
            projectId: response.projectId,
            branchId: response.branchId,
            environmentId: response.environmentId
          },
          values.projectName
        )
      })
      .catch((e: TError) => {
        onPublishStatus(false, e)
      })
      .finally(onFinish)
  }

  const handleLastStep = async (
    values: CreateProjectFormikValueTypes,
    setSubmitting: (value: boolean) => void
  ) =>
    publishProject(
      values,
      (success, projectResponse, name) => {
        if (success && projectResponse) {
          const { projectId: id } = projectResponse as POSTProjectResponse

          makeToast({
            title: t('createProject.responseMessage.success', {
              projectName: name
            }),
            message: '',
            type: 'success'
          })

          window.sessionStorage.removeItem(CREATE_PROJECT_STORAGE_ITEM)
          handleOnSubmit(id)
          return
        }
        makeToast({
          title:
            (projectResponse as TError).response?.data?.detail ??
            t('createProject.responseMessage.error'),
          message: '',
          type: 'error'
        })
      },
      () => {
        setSubmitting(false)
      }
    )

  const saveWarehouseCredentials = async (
    values: CreateProjectFormikValueTypes
  ): Promise<CreateProjectFormikValueTypes> => {
    let response
    let passphraseResponse

    if (values.agentsSecretsManagement === AgentsSecretsHost.MatillionHosted) {
      response = await mutateAsyncSecretValueV2Post({
        values: createSecretValueV2MutationData({
          ...values,
          name: values.environmentName,
          secretValue: {
            password: values.secretValue
          }
        })
      })

      if (values.passphrase && enableKeyPairAuthentication) {
        passphraseResponse = await mutateAsyncSecretValueV2Post({
          values: createSecretValueV2MutationData({
            ...values,
            name: values.environmentName,
            secretValue: {
              password: values.passphrase
            }
          })
        })
      }
    } else {
      const { createSecretRefResponse, createPassphraseSecretRefResponse } =
        await handleCHASecretReferences(values, !!enableKeyPairAuthentication)

      response = createSecretRefResponse
      passphraseResponse = createPassphraseSecretRefResponse
    }
    setSecretReferenceId(response.secretId)
    return {
      ...values,
      secretReferenceId: response.secretId,
      passphraseSecretReferenceId: passphraseResponse?.secretId,
      // Manually resetting the first field of the Defaults page because Formik seems to re initialise the secret reference id
      // Which doesn't make the listener work on each of the fields
      defaultRole: initialValues.defaultRole,
      defaultDatabase: initialValues.defaultDatabase,
      compute: initialValues.compute
    }
  }

  const validateWarehouseCredentials = async (
    values: CreateProjectFormikValueTypes
  ) => {
    // Ensure that there is a connection to warehouse defaults role in order to continue
    // Otherwise it throws an error which is caught below and does not proceed to the next page
    switch (values.type) {
      case Warehouse.Snowflake:
        await mutateAsyncWarehouseRoleLookup({
          values: createWarehouseDefaultsMutation(
            values,
            !!enableKeyPairAuthentication
          )
        })
        break
      case Warehouse.Redshift:
        await mutateAsyncWarehouseDatabaseLookup({
          values: createWarehouseDefaultsMutation(values)
        })
        break
      case Warehouse.Databricks:
        await mutateAsyncWarehouseComputeResourceLookup({
          values: createGetWarehouseComputeResourceMutationData(values)
        })
        break
    }
  }

  const saveSecretLocation = async (values: CreateProjectFormikValueTypes) => {
    const response = await mutateAsyncSecretLocation({
      values: createPostSecretLocationMutationData(values)
    })
    setSecretLocationId(response.secretLocationId)
    return {
      values: {
        ...values,
        secretLocationId: response.secretLocationId
      }
    }
  }

  const checkAgentStatus = async (values: CreateProjectFormikValueTypes) => {
    if (isMHA(values)) {
      await mutateAsyncMHA()
      const { data, error, isError } = await mhaRefetch()
      if (isError && error?.response?.status !== 404) {
        throw error
      }
      const agentRunning = isMHARunning(data?.status)

      if (!agentRunning) {
        return { values, redirect: PROJECTS_CREATE_AGENT }
      }

      values = {
        ...values,
        matillionHostedAgentId: data!.agentId
      }
    }
    return { values }
  }

  const isMHA = (values: CreateProjectFormikValueTypes) =>
    values.agentsSecretsManagement === AgentsSecretsHost.MatillionHosted

  const generateCloudCredentialSecretName = ({
    projectName,
    environmentName
  }: {
    projectName: string
    environmentName: string
  }) => {
    const shortUuid = uuidv4().substring(0, 4)
    return `${projectName}-${environmentName}-${shortUuid}-DefaultCredentials`.replaceAll(
      ' ',
      '-'
    )
  }

  const getSecretReferenceIdOrName = (values: {
    awsSecretReferenceId?: string
    projectName: string
    environmentName: string
  }) => {
    const name = generateCloudCredentialSecretName(values)
    return values.awsSecretReferenceId
      ? { secretReferenceId: values.awsSecretReferenceId }
      : { secretName: name, name }
  }

  const getAgentId = (values: CreateProjectFormikValueTypes) =>
    isMHA(values) ? values.matillionHostedAgentId : values.etlAgent.id

  const upsertCloudCredentials = async (
    values: POSTSecretValueV2Request | PATCHSecretValueV2Request
  ) => {
    if ('secretReferenceId' in values) {
      await mutateAsyncSecretValueV2Patch({ values })
      return { secretId: values.secretReferenceId }
    } else {
      return mutateAsyncSecretValueV2Post({ values })
    }
  }

  const saveCloudCredentials = async (
    values: CreateProjectFormikValueTypes
  ): Promise<CreateProjectFormikValueTypes> => {
    const { secretId } = await upsertCloudCredentials(
      createSecretValueV2UpsertMutationData({
        agentId: getAgentId(values),
        secretLocationId: values.secretLocationId,
        secretValue: {
          accessKeyID: values.awsAccessKeyId,
          secretKey: values.awsSecretAccessKey,
          type: CloudProviders.AWS
        },
        ...getSecretReferenceIdOrName({
          ...values,
          awsSecretReferenceId:
            values.awsSecretReferenceId || awsSecretReferenceId
        })
      })
    )
    return { ...values, awsSecretReferenceId: secretId }
  }

  const validateCloudCredentials = async (
    values: CreateProjectFormikValueTypes
  ) => {
    return mutateAsyncWarehouseLocationLookup({
      values: createWarehouseDefaultsMutation(values)
    })
  }

  const handleCurrentStep = async (
    values: CreateProjectFormikValueTypes
  ): Promise<{
    values: CreateProjectFormikValueTypes
    redirect?: string
  }> => {
    if (nextStep === PROJECTS_CREATE_ENVIRONMENT) {
      return checkAgentStatus(values)
    }
    if (nextStep === PROJECTS_CREDENTIALS) {
      return saveSecretLocation(values)
    }
    if (currentStep === PROJECTS_CREDENTIALS) {
      values = await saveWarehouseCredentials(values)
      await validateWarehouseCredentials(values)
      return { values }
    }
    if (currentStep === PROJECTS_CLOUD_CREDENTIALS) {
      values = await saveCloudCredentials(values)
      await validateCloudCredentials(values)
      return { values }
    }
    return { values }
  }

  const submitForm = async (
    newValues: CreateProjectFormikValueTypes,
    { setSubmitting, resetForm }: FormikHelpers<CreateProjectFormikValueTypes>
  ) => {
    clearToasts()
    setSubmitting(true)

    if (isLastStep) {
      await handleLastStep(newValues, setSubmitting)
    } else {
      try {
        const { values, redirect } = await handleCurrentStep(newValues)
        if (redirect) {
          navigate(redirect as To, { replace: true })
        } else {
          resetForm({ values })
          navigate(nextStep as To, { replace: true })
        }
      } catch (error: unknown) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const err = error as AxiosError<any>

        const errorDetail = (err?.response?.data as ErrorResponse)?.detail
        const errorResponseStatus = err?.response?.status

        makeToast({
          title: t('error.unexpected.title'),
          message:
            errorResponseStatus === StatusCodes.BAD_REQUEST &&
            errorDetail !== undefined
              ? errorDetail
              : t('error.unexpected.message'),
          type: 'error'
        })
      } finally {
        setProjectType(newValues.type)
        setSubmitting(false)
      }
    }
  }

  return (
    <Form<CreateProjectFormikValueTypes>
      formikValues={{
        onSubmit: submitForm,
        initialValues,
        validationSchema: validationSchema,
        initialTouched: false,
        innerRef: useCallback(
          (el: FormikProps<CreateProjectFormikValueTypes> | null) => {
            const {
              projectConfiguration: projectConfigVal,
              type: projectWarehouseVal
            } = el?.values || {}

            if (projectConfigVal) {
              setProjectConfiguration(projectConfigVal)
            }
            if (projectWarehouseVal) {
              setProjectType(projectWarehouseVal)
            }
          },
          [setProjectType]
        )
      }}
      translationPrefix="createProject"
      stepInfo={{
        isLastStep,
        stepIndex,
        nextStep,
        progress
      }}
      persistingStorageId={CREATE_PROJECT_STORAGE_ITEM}
      persistenceExclusions={[
        'secretValue',
        'awsSecretAccessKey',
        'awsAccessKeyId'
      ]}
      removeSubmitButtonOnRoutes={[PROJECTS_CREATE_AGENT, PROVIDER_SELECTION]}
      buttonsClassName={
        location.pathname.includes(
          `${AppRoutes.getProjectsAdd()}/${PROJECTS_CONFIGURATION as string}`
        )
          ? classes['Form__Buttons--expanded-container']
          : undefined
      }
    >
      <CreateProjectRoutes />
    </Form>
  )
}

export default CreateProjectForm

import { useEffect, useState, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Typography } from '@matillion/component-library'
import { heap, useUser } from '@matillion/hub-client'

import classes from 'modules/Projects/StreamingListing/components/NoData/NoData.module.scss'
import { NoDataModal } from 'modules/Projects/StreamingListing/components/NoDataModal/NoDataModal'
import { Requirements } from 'modules/Projects/StreamingListing/components/Requirements/Requirements'

interface NoDataProps {
  onAddClick: VoidFunction
}

const NoData: FunctionComponent<NoDataProps> = ({ onAddClick }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'streamingListing'
  })
  const { organisation } = useUser()

  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    heap.track('add_first_pipeline_button_visible')
  }, [])

  return (
    <>
      <div className={classes.NoData__Container}>
        <Typography
          format="tm"
          className={classes.NoData__Title}
          data-testid="add-first-streaming-pipeline-title"
        >
          {t('common.title')}
        </Typography>
        <Typography
          format="bcm"
          className={classes.NoData__SupportText}
          data-testid="add-first-streaming-pipeline-support-text"
        >
          {t('common.supportText')}
        </Typography>
        <Requirements className={classes.NoData__Requirements} />
        <div className={classes.NoData__ButtonWrapper}>
          <Button
            size="lg"
            onClick={() => {
              setShowModal(true)
            }}
            text={t('viewFeatures')}
            alt="secondary"
            className={classes.NoData__Button}
            data-testid="view-streaming-features-button"
          />
          <Button
            size="lg"
            onClick={onAddClick}
            text={t('addButtonText')}
            className={classes.NoData__Button}
            data-testid="add-first-streaming-pipeline-button"
          />
        </div>
      </div>
      {showModal && (
        <NoDataModal
          onClose={() => {
            setShowModal(false)
          }}
          organisationSubdomain={organisation.subdomain}
        />
      )}
    </>
  )
}

export default NoData

import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { LoadingSpinner, Typography } from '@matillion/component-library'
import { useUser } from '@matillion/hub-client'

import { useGetStreamingAgents } from 'api/hooks/streaming'
import { AgentStatus } from 'api/types'

import { ExternalLink } from 'components/ExternalLink'
import { StatusBadge } from 'components/Streaming/StatusBadge/StatusBadge'
import { TypographyWithToolTip } from 'components/TypographyWithTooltip'

import classes from 'modules/Projects/StreamingPipelineDashboard/Summary/components/StreamingAgentSummary/StreamingAgentSummary.module.scss'
import { getAgentSetupUrl } from 'modules/utils'

import { StreamingPipelineStatus } from 'types/Streaming'

interface StreamingAgentSummaryProps {
  agentId: string
  hidden: boolean
  pipelineStatus: StreamingPipelineStatus
}

const StreamingAgentSummary: FunctionComponent<StreamingAgentSummaryProps> = ({
  agentId,
  hidden,
  pipelineStatus
}) => {
  const { t } = useTranslation()
  const { organisation } = useUser()
  const {
    data: runningAgents,
    isLoading: isRunningAgentsLoading,
    isError: isRunningAgentsError
  } = useGetStreamingAgents(AgentStatus.RUNNING)
  const {
    data: unknownAgents,
    isLoading: isUnknownAgentsLoading,
    isError: isUnknownAgentsError
  } = useGetStreamingAgents(AgentStatus.UNKNOWN, {
    enabled: pipelineStatus === StreamingPipelineStatus.UNKNOWN
  })
  const {
    data: stoppedAgents,
    isLoading: isStoppedAgentsLoading,
    isError: isStoppedAgentsError
  } = useGetStreamingAgents(AgentStatus.STOPPED, {
    enabled: pipelineStatus === StreamingPipelineStatus.UNKNOWN
  })

  if (hidden) {
    return null
  }

  if (
    isRunningAgentsLoading ||
    isUnknownAgentsLoading ||
    isStoppedAgentsLoading
  ) {
    return <LoadingSpinner data-testid="loading-spinner" />
  }
  const agents = [
    ...(runningAgents ?? []),
    ...(unknownAgents ?? []),
    ...(stoppedAgents ?? [])
  ]
  const agent = agents.find((a) => a.agentId === agentId)
  if (
    isRunningAgentsError ||
    isUnknownAgentsError ||
    isStoppedAgentsError ||
    !agent
  ) {
    return (
      <div data-testid="agent-error">
        <Typography
          format="bcl"
          className={classes.StreamingAgentSummary__Title}
        >
          {t('streamingPipelineDashboard.summary.fields.agent.title')}
        </Typography>
        <Typography
          format="bcs"
          className={classes.StreamingAgentSummary__Error}
        >
          {t('streamingPipelineDashboard.agentError')}
        </Typography>
      </div>
    )
  }

  return (
    <div data-testid="summary-agent">
      <Typography format="bcl" className={classes.StreamingAgentSummary__Title}>
        {t('streamingPipelineDashboard.summary.fields.agent.title')}
      </Typography>
      <div className={classes.StreamingAgentSummary__Details}>
        <div
          className={classes.StreamingAgentSummary__HeadingWithContent}
          data-testid="heading-content-pair"
        >
          <Typography
            format="mc"
            className={classes.StreamingAgentSummary__Heading}
            data-testid="heading"
          >
            {t('streamingPipelineDashboard.summary.fields.agent.name')}
          </Typography>
          <TypographyWithToolTip
            tooltip={agent.name}
            format="bcs"
            className={classes.StreamingAgentSummary__AgentName}
            data-testid="content"
          >
            <ExternalLink
              format="bcs"
              data-testid="agent-link"
              className={classes.StreamingAgentSummary__Link}
              href={getAgentSetupUrl(
                agent.id,
                organisation.subdomain,
                organisation.region
              )}
              text={agent.name}
            />
          </TypographyWithToolTip>
        </div>
        <div className={classes.StreamingAgentSummary__HeadingWithContent}>
          <Typography
            format="mc"
            className={classes.StreamingAgentSummary__Heading}
          >
            {t('streamingPipelineDashboard.summary.fields.agent.status')}
          </Typography>
          <StatusBadge status={agent.status} />
        </div>
      </div>
    </div>
  )
}

export default StreamingAgentSummary

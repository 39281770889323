import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { capitalize } from 'lodash'

import { Skeleton } from '@matillion/component-library'

import { useGetProject } from 'api/hooks'

import {
  AgentHostLabel,
  GitProvider,
  GitProviderRepositoriesResponse,
  WarehouseLabels
} from 'types'

import PanelColumn from './PanelColumn'
import classes from './ProjectDetailsSummary.module.scss'

const ProjectDetailsSummary = () => {
  const { projectId } = useParams()
  const { data: projectResponse, isLoading: isProjectLoading } = useGetProject(
    projectId!
  )
  const { t } = useTranslation()

  const provider = useMemo(() => {
    switch (projectResponse?.gitProvider) {
      case GitProviderRepositoriesResponse.Matillion:
      case GitProvider.Matillion:
        return t('projectDetail.summary.col3.values.mhg')
      case GitProviderRepositoriesResponse.Github:
      case GitProvider.Github:
        return t('projectDetail.summary.col3.values.github')
      case GitProviderRepositoriesResponse.AzureDevops:
      case GitProvider.AzureDevops:
        return t('projectDetail.summary.col3.values.azureDevops')
      case GitProviderRepositoriesResponse.Gitlab:
      case GitProvider.Gitlab:
        return t('projectDetail.summary.col3.values.gitlab')
      case GitProviderRepositoriesResponse.Bitbucket:
      case GitProvider.Bitbucket:
        return t('projectDetail.summary.col3.values.bitbucket')
      default:
        return t('projectDetail.summary.col3.values.unknown')
    }
  }, [projectResponse?.gitProvider, t])

  const agent =
    AgentHostLabel[
      capitalize(
        projectResponse?.agentAndSecretHostLocation
      ) as keyof typeof AgentHostLabel
    ]
  const warehouse =
    WarehouseLabels[
      capitalize(projectResponse?.warehouse) as keyof typeof WarehouseLabels
    ]

  if (isProjectLoading) {
    return <Skeleton width={374} height={74} />
  }
  return (
    <div
      data-testid="project-detail-summary-panel"
      className={classes.ProjectDetailsSummary}
    >
      <PanelColumn
        data-testid="agent"
        title={t('projectDetail.summary.col1.title')}
        subtitle={agent}
      />
      <PanelColumn
        data-testid="warehouse"
        title={t('projectDetail.summary.col2.title')}
        subtitle={warehouse}
      />
      <PanelColumn
        data-testid="repository"
        title={t('projectDetail.summary.col3.title')}
        subtitle={provider}
      />
    </div>
  )
}
export default ProjectDetailsSummary
